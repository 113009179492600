<template>
	<div class="modal" @keydown.esc="closeModal(0)"  @keydown.enter="closeModal(1)">
		<div class="modalCont">


			<div class="modalHead">
Bild Meta Daten ändern
			</div>

			<div class="modalMain">


				<div class="fields">
					<div>Title</div>
					<div><input ref="title" type="text" v-model="dat.title"></div>
				</div>

				<div class="fields">
					<div>Alt Text</div>
					<div><input type="text" v-model="dat.alt_text"></div>
				</div>

			</div>

			<div class="modalFooter">
				<div ><button  @click="closeModal(0)">Abbrechen</button></div>
				<div ><button  @click="closeModal(1)">Speichern</button></div>
			</div>
		</div>
	</div>
</template>

<script>


	export default {
		name: 'ImgMetaEditor',
		components: {

		},
		props:[
		'par'

		],
		mounted(){
			this.$refs.title.focus();

		}, // end mounted 
		data: 
		function(){
			return {
				dat : this.par.data,

			}
		}, // end data
		methods: {
			closeModal(p){

				// this.dialogData.response = p.target.value;
				this.par.callback(p);
			},



		} // end methods
	} // end export default

</script>



<style scoped lang="scss">

.modal {
	position: absolute;
	top: 0;
	left: 0rem;
	background-color: rgb(0,0,0, .5);
	width: 100vw;
	height: 100vh;
	z-index: 100;
		display: flex;
		justify-content: center;
		align-items: center;

		& > .modalCont {
			background-color: white;

			padding: 2rem;

			border-radius: 10px;
			box-shadow: 2px 2px 2px #555;

		}

		& .modalMain {
			min-height: 7rem;
			width: 48rem;
			text-align: center;

		}

		& .modalHead {
			text-align: center;
			background-color: var(--c12);
			color: white;
			font-weight: bold;
			padding: 0.3rem 1rem;
			margin-bottom: 0.7rem;

		}
	& .modalFooter {
		display: flex;
		justify-content: center;
		align-items: baseline;
		
		& div {
			margin: 1rem;
			min-width: 12rem;

			& button {
				width: 100%;
			}
		}
	}
}


.fields {
	display: flex;
	align-items: center;

	& div {
		margin: .2rem 0;
	}

	& > div:nth-child(1) {
		min-width: 12rem;
		margin-right: 1rem;
		text-align: right;
	}	
	& > div:nth-child(2) input {
		min-width: 30rem;
		// margin-right: 1rem;
		// text-align: right;
	}
}

</style>

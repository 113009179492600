<template>
	<div class="modal">
		<div class="modalCont">

			<div class="modalHead">Bild Import</div>


			<div class="modalMain">

				 <div class="inSec">
				 	<input ref="fIp" class="inputField" type="file" @change="croppie"/>
				 </div>

				<div class="imgSrcCont">

					<div class="imgSrcItem">
						<div>Ausschnitt für Vorschaubild</div>
						<div>
							<vue-croppie 
							ref="croppieRef1" 
							:enableOrientation="true" 
							:boundary="{ width: 400, height: 225}" 
							:viewport="{ width:180, height:135, 'type':'square' }"
							:showZoomer=false
							:enableResize=false
							:setZoom=.55
							/>
						</div>
					</div>

					<div class="imgSrcItem">
						<div>Ausschnitt für Vollbild</div>
						<div>
							<vue-croppie 
							ref="croppieRef2" 
							:enableOrientation="true" 
							:boundary="{ width: 400, height: 225}" 
							:viewport="{ width: 640/3, height: 480/3, 'type':'square' }"
							:showZoomer=false
							:enableResize=true
							:setZoom=.55
							/>
						</div>
						
					</div>

				</div>

				<div class="imgResCont">
					<div><button v-if="localImgIsLoaded" @click="crop">Vorschau</button></div>

					<div class="endBtns">
						<div><button  @click="closeModal(0)">Abbrechen</button></div>
						<div v-if="cropped1 !== null" ><button  @click="closeModal(1)">Speichern</button></div>
					</div>

					<div class="imgs">
						<img class="cropped" :src="cropped1">
						<img class="cropped" :src="cropped2">
					</div>
				</div>




			</div> <!-- end ModalMain -->


			<div class="modalFooter">

			</div>
		</div>
	</div>
</template>

<script>
import * as tools from '@/tools.js'; 



	export default {
		name: 'ImgImport',
		components: {


		},
		props:[
		'par',

		],
		mounted(){
			this.$refs.fIp.focus();


		}, // end mounted 
		data: 
		function(){
			return {
				dat: this.par,
				cropped1: null,
				cropped2: null,
				localImgIsLoaded: false,

			}
		}, // end data

		computed:{

		},
		methods: {


			croppie (e) {
				var files = e.target.files || e.dataTransfer.files;
				if (!files.length) return;

				this.localImgIsLoaded = true;

				var reader = new FileReader();
				reader.onload = e => {

					this.$refs.croppieRef1.bind({
						url: e.target.result
					});
					this.$refs.croppieRef2.bind({
						url: e.target.result
					});
				};


				reader.readAsDataURL(files[0]);
			},

			crop() {
      // Options can be updated.
      // Current option will return a base64 version of the uploaded image with a size of 600px X 450px.
      let options1 = {
      	type: 'base64',
      	size: { width: 180, height: 135 },
      	format: 'jpeg'
      };

      let f = 2.88;
      let options2 = {
      	type: 'base64',
      	size: { width: this.$refs.croppieRef2.croppie.options.viewport.width*f, height: this.$refs.croppieRef2.croppie.options.viewport.height*f },
      	format: 'jpeg'
      };

      this.$refs.croppieRef1.result(options1, output => {
      	this. src1 = this.cropped1 = output;

      });

      this.$refs.croppieRef2.result(options2, output => {
      	this. src2 = this.cropped2 =  output;

      });






    },
			closeModal(p){
				if (p === 1) {
					this.$parent.impImg.data = {tn: this.cropped1, full: this.cropped2};
			}

			this.dat.callback(p);
		},



		} // end methods
	} // end export default

</script>



<style scoped lang="scss">

.container {
	// background-color: #EEE472;
	
}

.modal {
	position: absolute;
	top: 0;
	left: 0rem;
	background-color: rgb(0,0,0, .5);
	width: 100vw;
	height: 100vh;
	z-index: 100;
		display: flex;
		justify-content: center;
		align-items: center;

		& > .modalCont {
			background-color: white;

			padding: 2rem;

			border-radius: 10px;
			box-shadow: 2px 2px 2px #555;

		}

		& .modalMain {
			// min-height: 7rem;
			width: 60rem;
			// display: flex;
			// flex-wrap: wrap;
			// & div {
			// 	box-sizing: border-box;
			// 	flex: 0 0 50%;
			// 	// margin: .4rem 0;

			// 	& > div:first-child  {
			// 	color: gray;
			// 	margin: 0.2rem 0.1rem 0;
			// 	}



			// 	& div input {
			// 		width: 94%;
			// 	// line-height: 12px !important;
			// 	border: 1px solid #ddd;
			// 	border-radius: 4px;
			// 	padding: .2rem .6rem;
			// 	}


			// }

		}

	& .modalHead {
background-color: #ddd;
padding: 0.4rem 0.8rem;
font-size: 1.1rem;
font-weight: bold;
text-align: center;

	}
	& .modalFooter {
		display: flex;
		justify-content: center;
		align-items: baseline;
		
		& div {
			margin: 1rem;
			min-width: 12rem;

			& button {
				width: 100%;
			}
		}
	}
}

.modalMain .imgSrcCont {
	overflow: auto;
	display: flex;
	flex-wrap: wrap;
	& > div.imgSrcItem {
		flex: 1 1 30%;

		& > div:nth-child(1) {
			margin: .4rem;
			padding: .1rem 1rem;
			background-color: var(--c12);
			color: white;
		}		
		
	}	
}


.modalMain .imgResCont {
	display: flex;
	align-items: center;
	flex-direction: column;

	& div {
		& button {
			min-width: 22rem;
		}

	}
		& div.imgs {
			margin: 1rem;
			display: flex;
			justify-content: center;
			align-items: center;
			& img {
				margin: 1rem;
					// border: 1px solid silver;

				&:first-child {
					// border-radius: 50%;
					
				}
				&:nth-child(2) {
					width: 50%;
					
				}
			}
		}

	
}

.modalMain input.inputField {
	margin: 1rem 0;
}

.inSec {
display: flex;
justify-content: space-between;
align-items: center;
	
}

.modal .endBtns {
	width: 100%;
	display: flex;
	justify-content: center;
		margin: .6rem 0;

		& div {	
			margin: 0 1rem;
		}
}


</style>
